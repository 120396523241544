@media print {
    #content {
      font-size: 12px !important;
      padding: 0.2mm;
      margin: 0;
    }
  
    /* Otros estilos de impresión */
    img {
      width: 100%; /* Asegúrate de que la imagen se ajuste al ancho de la página */
      height: auto; /* Mantiene las proporciones de la imagen */
    }
  
    /* Ocultar elementos no necesarios en la impresión */
    .no-print {
      display: none;
    }

    th, td{
      border: 1px solid black;
      border-width: 1px !important;
    }

    .tablaQuerato th, 
    .tablaQuerato td {
      border: 0px;
      border-width: 0px !important;
    }
    
    th, td{
      border: 1px solid black ;
      border-width: 1px !important;
    }

    .tablaQuerato th, 
    .tablaQuerato td {
      border: 0px;
      border-width: 0px !important;
    }
  }

  