body {
  margin: 0;
  font-family: 'Inter', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  padding-top: 60px;
}
input{
  background-color: #ffffff !important;
  border-color: #bdbdbd !important;
  color: rgb(110, 110, 110) !important;
}

input::placeholder{
  color: rgb(138, 138, 138) !important;
}
select{
  background-color: #ffffff !important;
  border-color: #afafaf !important;
}
textarea{
  background-color: #ffffff !important;
  border-color: #b9b7b7 !important;
}

select option{
  color: black !important;
}
.form-select{
  color: rgb(0, 0, 0) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar{
  --bs-navbar-padding-y: 0rem !important;
}

.navbar-custom .nav-link {
  color: #000000 !important;
}

.navbar-custom .nav-link:hover {
  color: #000000 !important;
}
.table-dark {
  --bs-table-bg: rgb(236, 236, 236) !important;
  --bs-table-border-color: rgb(56, 56, 56) !important;
  text-align: center !important;
}

.table>:not(caption)>*>* {
  color:#000000 !important;
}
.formCliente{
  display: grid;
  justify-content: center;
  max-width: 100% !important;
}
.formCliente span{
  color: #000000 !important;
}

.formCliente h2{
  color: #000000 !important;
}

.formCliente h3{
  color: #000000 !important;
}

.formCliente div{
  color: rgb(0, 0, 0) !important;
}

.contenedorTodasCitas{
  color: rgb(228, 228, 228) !important;
}

.limited-width-select {
  max-width: 200px; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
}

.ContenedorNuevaCita{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tablaPedidos td{
  vertical-align: middle;
}

.titlePedidoVista h2{
  margin: 0 !important;
}

.tablaVistaHistorico{
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0 15px 0;
  font-size: 18px;
  text-align: left;
}

.tablaVistaHistorico thead tr {
  background-color: #8f8f8f;
  color: #0c0c0c;
  text-align: center;
}

.tablaVistaHistorico th, .tablaVistaHistorico td {
  padding: 5px 7px;
  border: 1px solid #acacac;
  text-align: center;
}

.tablaVistaHistorico tbody tr {
  border-bottom: 1px solid #dddddd;
}

.tablaVistaHistorico tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.tablaVistaHistorico tbody tr:last-of-type {
  border-bottom: 2px solid #919191;
}

.citasTable td{
  vertical-align: middle; 
  text-align: center;
}

.FuenteExportarHistorico{
  font-size: 18px;
}
/*Acciones--------------------------------------------------------Clientes--------------------*/


.dropbtn {
  color: white; 
  border: none; 
  padding: 10px 15px;
  font-size: 16px; 
  cursor: pointer;
  border-radius: 5px; 
  left: -130px;
}

.GearIcon {
  max-width: 30px;
}

.dropdown {
  position: relative; 
  display: inline-block; 
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  padding: 8px;
  min-width: 200px;
  max-width: 250px;
  z-index: 10;
  bottom: 35px;
  right: 35px;
}

.dropdown-content button {
  background: transparent;
  border: none;
  padding: 8px 12px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.dropdown-content button:hover {
  background: #f5f5f5;
  border-radius: 4px;
}

.dropbtn.active {
  background: #e0e0e0;
  border-radius: 50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/****************DETALLES ULTIMA VISITA RESPONSIVE*************/

@media (min-width: 768px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr; 
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; 
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: 100%;
  height: auto;
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}

/*************VALIDAR EMAIL*****************/
.validate-email-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.validate-email-view-message-box {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.validate-email-view-message-box h1 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.validate-email-view-success {
  background-color: #dff0d8;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.validate-email-view-error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}
/****************LOADER**********************/

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #5e5d5d;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #007ba1;
  border-bottom: 4px solid transparent;
  animation: spin 0.5s linear infinite reverse;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 


/*************CITAS CREAR***********/
.ContenedorNuevaCita {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.form-container {
  flex: 1;
}

.citas-container {
  flex: 1;
  max-height: 300px; /* Ajusta según tus necesidades */
  overflow-y: auto;
  border-left: 1px solid #ddd;
  padding-left: 20px;
}

.citas-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.citas-container li {
  margin-bottom: 10px;
}

#tablaCitas{
  width: 1100px;
}

#listaCitas li{
  text-decoration: underline;
  text-align: left;
}

#bodyCitasT td{
  padding: 10px 5px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto; 
}

.inputTextTransparent{
  color: transparent !important;
}

#scannCode {
  transition: border-color 0.5s ease; /* Suaviza la transición del color del borde */
}

#scannCode:focus {
  border: 2px solid #5b9e8f !important; /* Establece un borde verde claro */
  outline: none; /* Elimina el contorno predeterminado */
}